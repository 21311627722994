import { NotificationService } from './../../../@core/services/notification.service';
import { AlertService } from './../../../@core/services/alert.service';
import { UserService } from './../../../@core/services/user.service';
import { AuthService } from './../../../@core/services/auth.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';


import { NbDialogService, NbWindowService } from '@nebular/theme';
import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { filter, map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { ViewFleetDispatchComponent } from './../../../Modules/sales/dispatch/view-fleet-dispatch/view-fleet-dispatch.component';
import { NotificationsViewComponent } from '../notifications-view/notifications-view.component';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  componentNotification = NotificationsViewComponent
  showChangePassword = false;
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  userMenu = [
    // { title: 'Profile' },
    {
      title: 'Cambiar Contraseña',
      icon: { icon: 'unlock-keyhole', pack: 'fad' },
      data: 'action-change-password'
    },
    {
      title: 'Cerrar sesion',
      icon: { icon: 'arrow-right-from-bracket', pack: 'fad' },
      data: 'action-logout'
    }
  ];
  userData: any;
  showPassword = false;
  newPassword = '';
  oldPassword = null;
  repeatNewPassword = '';
  guardandoCambioDeContraena = false;
  numNotifications = 0;
  notifications: any[] = [];

  constructor(private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private nbDialogService: NbDialogService,
    private themeService: NbThemeService,
    private userService: UserData,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private authS: AuthService,
    private userS: UserService,
    private alertS: AlertService,
    private notificationService: NotificationService,
    private route: Router) {
  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;

    this.userService.getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => this.user = users.nick);

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);
    this.userData = this.authS.getUserData();

    this.menuService.onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'menu-header'),
      )
      .subscribe(data => {
        if (data?.item?.data?.includes('action')) {
          const key = data.item.data
          switch (key) {
            case 'action-change-password':
              this.showChangePassword = true;
              break;
            case 'action-logout':
              this.logOutFunction();
              break;
            default:
              break;
          }
        }
      }
      );

      this.notificationService.getNotificationsByUser(this.userData?.id).pipe(takeUntil(this.destroy$)).subscribe(
        resp => {
          if (resp?.data?.length > 0) {
            resp.data = resp.data.map((x: any) => {
              if (x?.configFCM) {
                x.configFCM = JSON.parse(x.configFCM);
              }
              return x;
            })
            this.notifications = resp.data;
            console.log('datos', resp.data);
            this.numNotifications = resp.data.filter((x: any) => x?.readed !== 1).length;
          }
        }
      );

      const notificationSocket = this.notificationService.readNotification();
      if (notificationSocket) {
        notificationSocket.pipe(takeUntil(this.destroy$)).subscribe({
          next: (data: any) => {
            console.log(data);
            if (data?.configFCM) {
              try {
                data.configFCM = JSON.parse(data.configFCM);
              } catch (error) {
              }
            }

            if (data?.readed !== 1 && !data?.fecha_modificacion) {
              this.notifications.unshift(data);
              this.alertS.fireAlert(data?.titulo_notificacion, 'warning', data?.texto_notificacion, false, true, this.redirectData.bind({component: this, data: data}));
            } else {
              const id_find = Number(data?.id ?? 0);
              const index = this.notifications.findIndex((x: any) => Number(x?.id ?? '0') === id_find);
              console.log('findObject', index);
              if (index >= 0) {
                this.notifications[index] = data;
              } else {
                this.notifications.unshift(data);
              }
            }
            console.log('notificaciones', this.notifications);
            this.numNotifications = this.notifications.filter((x: any) => x?.readed !== 1).length;
          },
          error: (error: any) => {
            console.log('errorNotificationSocket', error);
          },
          complete: () => {
            console.log('finNotificationSocket');
          },
        });
      }
  }

  async logOutFunction() {
    localStorage.clear();
    this.route.navigate(['/auth/login']);
    await this.notificationService.deleteToken();
    location.reload();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    // this.menuService.navigateHome();
    this.route.navigate(['/']);
    return false;
  }

  openDistpaches() {
    const dispatchs = this.nbDialogService.open(ViewFleetDispatchComponent, {
      context: {
        modal: true,
      },

    });
  }
  reload() {
    window.location.reload();
  }

  getInputType() {
    return this.showPassword ? 'text' : 'password';
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }

  hidePasswordModal() {
    this.showChangePassword = false;
  }

  changePassword() {
    this.guardandoCambioDeContraena = true;
    const user = this.authS.getUserData();
    this.userS.changePassword(this.oldPassword, this.newPassword, user.id, user.id).subscribe({
      next: (data: any) => {
        console.log('changePassword', data)
        if (data?.success) {
          this.alertS.fireAlert('🎉 Éxito', 'success', 'Contraseña cambiada con éxito');
        } else {
          this.alertS.fireAlert('🚫 Error', 'error', 'Error al intentar cambiar contraseña');
        }
      },
      error: (err) => {
        console.log('err', err);
        this.guardandoCambioDeContraena = false;
        this.alertS.fireAlert('🚫 Error', 'error', 'Error al intentar cambiar contraseña');
      },
      complete: () => {
        console.log('terminar servicio');
        this.guardandoCambioDeContraena = false;
      }
    });
  }

  redirectData(toast: any) {
    const comp: any = this;
    const component = comp.component;
    const locationHost = window.location?.origin + '/#';
    const data: any = comp.data;
    console.log('toast', toast);
    console.log('data', data);
    console.log('locationHost', locationHost);
    console.log('ruta', data?.configFCM?.link.replace(locationHost, ''))
    component.route.navigate([data?.configFCM?.link.replace(locationHost, '')]);
    toast.close();
  }
}

<nb-card class="mb-0 vh-100 vw-80">
  <nb-card-header class="p-0">
    <div class="col-12 text-end" *ngIf="modal">
      <button nbButton ghost status="danger" [ngClass]="{'m-2': true}" (click)="cerrarModal()">
        <nb-icon icon="close-outline" pack="eva"></nb-icon>
      </button>
      &nbsp;
    </div>
    <div class="col-12 m-2 text-center">
        Seleccionar Fecha:<br>
      <div class="d-flex justify-content-center w-100">
        <button nbButton ghost status="danger" size="small" *ngIf="dateFilter" (click)="clearDateFilter()"
        [ngStyle]="{'padding': '0'}"
        [ngClass]="{'mx-2': true}">
          <nb-icon icon="close-outline" pack="eva"></nb-icon>
        </button>
        <nb-form-field>
          <nb-icon nbPrefix icon="calendar-outline" pack="eva"></nb-icon>
          <input type="text" placeholder="Selecciona Fecha" nbInput readonly
            [fieldSize]="'tiny'"
            [ngClass]="{'text-center': true}"
            [(ngModel)]="date"
            [nbDatepicker]="dateFleetDispatch"
            (ngModelChange)="selectDate($event);">
        </nb-form-field>
        <nb-datepicker #dateFleetDispatch format="dd/MM/yyyy" [showWeekNumber]="true">
        </nb-datepicker>
      </div>
    </div>
    <div class="col-12 mx-1 py-2" [nbSpinner]="loadingData">
      <p-scrollPanel
        [style]="{ width: '100%', height: '80px' }"
        styleClass="px-2 ps-0"
      >
        <div class="d-flex">
          <nb-alert
            class="mr-1 mb-0 px-1 py-0 cursor-pointer temporal-pallet"
            [ngStyle]="{ 'margin-left': '1.8px', 'margin-right': '1.8px' }"
            *ngFor="
              let item of flotas
            "
            [status]="item === flotaSeleccionada ? 'success' : 'primary'"
            (click)="seleccionarFlota(item)"
          >
            <div class="my-0 mx-2 d-flex justify-content-center align-items-center h-100">
              <div class="text-center" style="width: 120px">
                <b>{{item?.plate}}</b><br>
                <span>{{(item?.numero_de_eventos || 0)}} Despachos</span><br>
                <span *ngIf="item === flotaSeleccionada" [ngStyle]="{'font-size': '10px'}"><b>SELECCIONADO</b></span>
                <span *ngIf="item !== flotaSeleccionada" [ngStyle]="{'font-size': '10px'}"><b>NO SELECCIONADO</b></span>
              </div>
            </div>
          </nb-alert>
        </div>
      </p-scrollPanel>
    </div>
  </nb-card-header>
  <nb-card-body [nbSpinner]="loadingData">
    <app-view-fleet-date-dispatch [currentSelected]="flotaSeleccionada" [currentDate]="dateSelectedCalendar" [currentDateString]="dateString"></app-view-fleet-date-dispatch>
  </nb-card-body>
</nb-card>

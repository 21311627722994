import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import {
  NbAuthComponent,
  NbLoginComponent,
  NbLogoutComponent,
  NbRegisterComponent,
  NbRequestPasswordComponent,
  NbResetPasswordComponent,
} from '@nebular/auth';
import { AuthGuardService } from './@core/services/authguard.service';
import { PrintLayoutComponent } from './Modules/shared-components/print-layout/print-layout.component';
import { InvoiceLayoutComponent } from './Modules/shared-components/invoice-layout/invoice-layout.component';
const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./Modules/modules.module')
      .then(m => m.ModulesModule),
  },
  {
    path: 'print',
    outlet: 'print',
    component: PrintLayoutComponent,
    children: [
      {path: ':tipo/:ids', component: InvoiceLayoutComponent},
    ],
    data: {
      role: ['admin', 'calidad', 'planta', 'prueba'],
      Module: 'MODULES',
    },

  },
  {
    path: 'auth',
    component: NbAuthComponent,

    loadChildren: () => import('./Modules/auth/auth.module')
      .then(m => m.AuthModule),
    // children: [
    //   {
    //     path: '',
    //     component: NbLoginComponent,
    //   },
    //   {
    //     path: 'login',
    //     component: NbLoginComponent,
    //   },
    //   {
    //     path: 'register',
    //     component: NbRegisterComponent,
    //   },
    //   {
    //     path: 'logout',
    //     component: NbLogoutComponent,
    //   },
    //   {
    //     path: 'request-password',
    //     component: NbRequestPasswordComponent,
    //   },
    //   {
    //     path: 'reset-password',
    //     component: NbResetPasswordComponent,
    //   },
    // ],
  },
  { path: '', redirectTo: 'pages', pathMatch: 'full' },
  { path: '**', redirectTo: 'pages' },
];

const config: ExtraOptions = {
  useHash: true,
};

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}

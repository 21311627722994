import { FleetService } from './../../../../@core/services/fleet.service';
import { AfterViewInit, Component, OnInit, Optional, ViewChild } from '@angular/core';
import { NbDateService, NbDatepicker, NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'app-view-fleet-dispatch',
  templateUrl: './view-fleet-dispatch.component.html',
  styleUrls: ['./view-fleet-dispatch.component.scss']
})
export class ViewFleetDispatchComponent implements OnInit, AfterViewInit {

  flotaSeleccionada = null;
  flotas: any = [];
  date: any = new Date();
  dateFilter = false;
  loadingData = false;
  dateSelectedCalendar = new Date();
  dateString: string = '';
  @ViewChild('dateFleetDispatch') dateFleetDispatch: any;
  modal= false;

  constructor(private fleetS: FleetService,
    @Optional() private dialogref: NbDialogRef<any>,) {
  }

  ngAfterViewInit(): void {
    console.log('dateFleetDispatch', this.dateFleetDispatch);
    this.dateFleetDispatch.firstDayOfWeek = 2;
  }

  ngOnInit(): void {
  }

  seleccionarFlota(flota: any) {
    this.flotaSeleccionada = flota;
  }

  clearDateFilter() {
    this.dateFilter = false;
    this.date = new Date();
    this.selectDate(new Date());
  }

  selectDate(fecha: any) {
    fecha = fecha?.toISOString()?.split('T') || fecha;
    console.log('fecha', fecha);
    this.dateFilter = true;
    const parts = fecha[0].split('-');
    this.dateString = fecha[0];
    const date = new Date(parts[0], parts[1] - 1, parts[2]);
    this.dateSelectedCalendar = date;
    this.loadingData = true;
    this.fleetS.getByDateDispatch(fecha[0]).subscribe({
      next: (resp: any) => {
        console.log('resp', resp);
        this.flotas = [];
        if (resp.success && resp.data) {
          this.flotas = resp.data;
          if (this.flotas.length > 0) {
            this.flotaSeleccionada = this.flotas[0];
          } else {
            this.flotaSeleccionada = null;
          }
        }
      },
      error: (err: any) => {
        console.log('error', err);
        this.loadingData = false;
      },
      complete: () => {
        console.log('completado');
        this.loadingData = false;
      }
    });
  }

  cerrarModal() {
    if (this.dialogref) {
      this.dialogref.close();
    }
  }
}

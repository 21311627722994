import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { EnvironmentProviders, ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  NbActionsModule,
  NbLayoutModule,
  NbMenuModule,
  NbSearchModule,
  NbSidebarModule,
  NbUserModule,
  NbContextMenuModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbThemeModule,
  NbFormFieldModule,
  NbInputModule,
  NbCardModule,
  NbTooltipModule,
  NbSpinnerModule,
  NbListModule,
  NbBadgeModule,
  NbPopoverModule,
} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbSecurityModule } from '@nebular/security';

import {
  FooterComponent,
  HeaderComponent,
  SearchInputComponent,
  TinyMCEComponent,
} from './components';
import {
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
} from './pipes';
import {
  OneColumnLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent,
} from './layouts';
import { DEFAULT_THEME } from './styles/theme.default';
import { COSMIC_THEME } from './styles/theme.cosmic';
import { CORPORATE_THEME } from './styles/theme.corporate';
import { DARK_THEME } from './styles/theme.dark';
import { DialogModule } from 'primeng/dialog';
import { NotificationsViewComponent } from './components/notifications-view/notifications-view.component';
import { TooltipModule } from 'primeng/tooltip';

const NB_MODULES = [
  NbLayoutModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbContextMenuModule,
  NbSecurityModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbEvaIconsModule,
  DialogModule,
  NbInputModule,
  NbFormFieldModule,
  FormsModule,
  NbCardModule,
  NbTooltipModule,
  NbSpinnerModule,
  NbListModule,
  NbBadgeModule,
  TooltipModule,
  RouterModule,
  NbPopoverModule
];
const COMPONENTS = [
  HeaderComponent,
  FooterComponent,
  SearchInputComponent,
  TinyMCEComponent,
  OneColumnLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent,
];
const PIPES = [
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
];


const nbThemeModule: (Provider | EnvironmentProviders)[] | undefined | any = NbThemeModule.forRoot(
  {
    name: 'default',
  },
  [ DEFAULT_THEME, COSMIC_THEME, CORPORATE_THEME, DARK_THEME ],
).providers;

@NgModule({
  imports: [CommonModule, ...NB_MODULES],
  exports: [CommonModule, ...PIPES, ...COMPONENTS],
  declarations: [...COMPONENTS, ...PIPES, NotificationsViewComponent],
})

export class ThemeModule {
  static forRoot(): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
      providers: [
        ...nbThemeModule,
      ],
    };
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ModuleService {
  PATHAPI: string = 'api/module';

  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<any>(environment.apiURL + this.PATHAPI);
  }

  getByTypeUser(id_type_user: any) {
    return this.http.get<any>(environment.apiURL + this.PATHAPI + '/' + id_type_user);
  }

  assigmentToTypeUser(body: any, id_type_user: number) {
    const object = {items: body, id_type_user: id_type_user};
    return this.http.post<any>(environment.apiURL + this.PATHAPI + '/setModulesTypeUser', object);
  }

  save(data: any) {
    return this.http.post<any>(environment.apiURL + this.PATHAPI + '/create', data);
  }

  update(data: any) {
    return this.http.patch<any>(environment.apiURL + this.PATHAPI + '/update', data);
  }

  updateArray(data: any) {
    return this.http.post<any>(environment.apiURL + this.PATHAPI + '/reorder', data);
  }

  delete(id: any) {
    return this.http.delete<any>(environment.apiURL + this.PATHAPI + '/' + id);
  }
}

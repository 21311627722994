<nb-card class="mb-0">
  <nb-card-header
    class="d-flex justify-content-between align-items-center py-1"
  >
    <div>Notificaciones</div>
    <div></div>
    <!-- <div>
      <button
        nbButton
        ghost
        (click)="toggleView()"
        status="primary"
        nbTooltip="Configuraciones"
        nbTooltipStatus="primary"
      >
        <nb-icon icon="settings-outline"></nb-icon>
      </button>
      <button
        *ngIf="isModal()"
        nbButton
        status="danger"
        shape="round"
        size="small"
        (click)="closeDialog()"
        [nbTooltip]="'Cerrar'"
        nbTooltipPlacement="bottom"
        nbTooltipStatus="danger"
      >
        <nb-icon icon="close-outline"></nb-icon>
      </button>
    </div> -->
  </nb-card-header>
  <nb-card-body class="p-0 m-0">
    <nb-card size="tiny" [nbSpinner]="loadingNotif">
      <nb-list
        nbInfiniteList
        [threshold]="10"
        class="w-100"
        >
        <!-- (bottomThreshold)="loadNext()" -->
        <nb-list-item
          *ngFor="let noti of notifications"
          class="py-2 w-100 pe-0"
        >
        <input [ngStyle]="{'display': 'none'}"  type="hidden" value="{{noti?.configFCM?.link?.replace(locationHost, '')}}" id="url">
        <input [ngStyle]="{'display': 'none'}"  type="hidden" value="{{noti?.configFCM?.link}}" id="urlOriginal">
          <div class="row w-100">
            <!-- <div class="col-1 pe-0">
              <nb-badge
                size="large"
                [dotMode]="true"
                [status]="noti?.readed === 1 ? 'success' : 'warning'"
              ></nb-badge>
            </div> -->
            <div class="col-1 text-center justify-content-center align-content-center align-items-center">
              <nb-icon [pack]="noti?.readed !== 1 ? 'fas': 'fad'" icon="bell" [status]="noti?.readed === 1 ? 'primary' : 'warning'"></nb-icon>
            </div>
            <div class="col-10">
              <div class="row cursor-pointer" (click)="testNotify(noti)">
                <div class="col-12 pe-0" (click)="testNotify(noti)" [ngClass]="{'fw-bold': noti?.readed !== 1 }">
                  {{ noti?.titulo_notificacion }}
                </div>
                <div
                  class="col-12 text-truncate"
                  [pTooltip]="noti?.texto_notificacion"
                  appendTo="body"
                  [tooltipZIndex]="'9999'"
                  [escape]="false"
                  style="max-height: 30px; max-width: 320px"
                >
                  {{ noti?.texto_notificacion }}
                </div>
              </div>
              <div class="row">
                <div class="col-12 mt-3 d-flex flex-column">
                  <!-- <small>
                    Enviado por
                    <a href="mailto:{{ noti?.UserNickSend }}">{{
                      noti.UserNickSend
                    }}</a>
                  </small>
                  <small (click)="testNotify(noti)">
                    {{ noti.createdAt | date : "medium" }}</small
                  > -->
                </div>
              </div>
            </div>
            <!-- <div class="col-1 d-flex align-items-center p-0">
              <div>
                <nb-icon
                  [nbTooltip]="
                    'B·Connect: ' + (noti.Channels.WebPush ? '✅' : '❌')
                  "
                  pack="bilbo"
                  icon="bc"
                  [status]="noti.Channels.WebPush ? 'success' : 'danger'"
                ></nb-icon>
                <nb-icon
                  [nbTooltip]="
                    'Socket: ' + (noti.Channels.Socket ? '✅' : '❌')
                  "
                  pack="fal"
                  icon="bell"
                  [status]="noti.Channels.Socket ? 'success' : 'danger'"
                ></nb-icon>
                <nb-icon
                  [nbTooltip]="'Email: ' + (noti.Channels.Email ? '✅' : '❌')"
                  pack="fal"
                  icon="envelope"
                  [status]="noti.Channels.Email ? 'success' : 'danger'"
                ></nb-icon>
                <nb-icon
                  [nbTooltip]="
                    'Whatsapp: ' + (noti.Channels.Whatsapp ? '✅' : '❌')
                  "
                  pack="fab"
                  icon="whatsapp"
                  [status]="noti.Channels.Whatsapp ? 'success' : 'danger'"
                ></nb-icon>
              </div>
            </div> -->
            <div class="col-1 d-flex align-items-center p-0">
              <button
                *ngIf="noti?.configFCM?.link"
                nbButton
                ghost
                status="primary"
                [routerLink]="[noti?.configFCM?.link?.replace(locationHost, '')]"
                nbTooltip="Abrir Módulo"
                nbTooltipStatus="primary"
                (click)="openNoti(noti)"
              >
                <nb-icon icon="external-link-outline"></nb-icon>
              </button>
              <button
                *ngIf="!noti?.configFCM?.link"
                nbButton
                ghost
                status="primary"
                (click)="openNoti(noti)"
                nbTooltip="Marcar Leído"
                nbTooltipStatus="primary"
              >
                <nb-icon icon="checkmark-circle-2-outline"></nb-icon>
              </button>
            </div>
          </div>
        </nb-list-item>
      </nb-list>
    </nb-card>
  </nb-card-body>
</nb-card>

<table>
  <tbody>
  <tr>
    <td>
      <div class="content">
        <router-outlet></router-outlet>
      </div>
    </td>
  </tr>
  </tbody>
  <tfoot>
  <tr>
    <td>
      <div class="fecha-footer">
        <div class="fecha-print" *ngIf="viewDate">{{date | date: 'full'}}</div>
      </div>
    </td>
  </tr>
  </tfoot>
</table>

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  PATHAPI: string = 'api/user';

  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<any>(environment.apiURL + this.PATHAPI);
  }

  save(data: any) {
    return this.http.post<any>(environment.apiURL + this.PATHAPI + '/create', data);
  }

  update(data: any) {
    return this.http.patch<any>(environment.apiURL + this.PATHAPI + '/update', data);
  }

  delete(id: any) {
    return this.http.delete<any>(environment.apiURL + this.PATHAPI + '/' + id);
  }

  getByID(id: any) {
    return this.http.get<any>(environment.apiURL + this.PATHAPI + '/' + id);
  }

  changePassword(old_password: string | null, new_password: string, id_user: Number, id_current_user: Number) {
    const objeto = {old_password, new_password, id_user, id_current_user};
    return this.http.patch<any>(environment.apiURL + this.PATHAPI + '/changePassword', objeto);
  }
}

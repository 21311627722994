<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo px-1" href="#" (click)="navigateHome()">
      <img class="p-0 img-fluid" src="./assets/aquaher.svg" alt="logo Aquaher" />
    </a>
  </div>
  <!--    <nb-select class="ml-5" [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">-->
  <!--      <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>-->
  <!--    </nb-select>-->
</div>

<div class="header-container">
  <nb-actions size="small">
    <!-- <nb-action nbButton (click)="openVideoPlayer()" [nbSpinner]="loadingVideo" [disabled]="loadingVideo"
               [class]="isMobile() ? 'px-2': ''" *ngIf="urlVideo"
               [nbTooltip]="'Video Tutorial'" nbTooltipPlacement="bottom" nbTooltipStatus="danger">
      <nb-icon pack="fad" icon="clapperboard-play" status="danger"></nb-icon>
    </nb-action>
    <nb-action nbButton class="control-item" [nbTooltip]="'Versión ' + appVersion" nbTooltipPlacement="bottom"
               nbTooltipStatus="info" (click)="viewVersion()">
      <nb-icon pack="fad" [icon]="isLocalProd ? 'desktop' : 'earth-americas'" status="primary"></nb-icon>
    </nb-action> -->
    <nb-action nbButton class="control-item" nbTooltip="Recargar" nbTooltipPlacement="bottom" nbTooltipStatus="info"
      (click)="reload()">
      <nb-icon pack="fad" icon="rotate" status="primary"></nb-icon>
    </nb-action>

    <nb-action nbButton class="control-item" nbTooltip="Buscar Orden" nbTooltipPlacement="bottom"
      nbTooltipStatus="warning" (click)="openDistpaches()">
      <nb-icon icon="calendar-circle-exclamation" pack="far" status="primary"></nb-icon>
    </nb-action>


    <!-- <nb-action #pop [nbPopover]="component"
               nbPopoverClass="customPopOver"
               [nbPopoverContext]="{ popover: popover || null}"
               nbButton class="control-item">
      <nb-icon pack="fad" icon="message-captions" status="primary"></nb-icon>
    </nb-action> -->
    <nb-action #popNotif
               nbPopoverClass="customPopOver"
               [badgePosition]="'top end'"
               [badgeStatus]="'success'"
               [badgeText]="numNotifications.toString()"
               [nbPopover]="componentNotification"
               [nbPopoverContext]="{ popover: popNotif || null, notifications: notifications}"
               nbButton class="control-item noti">
      <nb-icon pack="fad" icon="bell" status="primary"></nb-icon>

      <!--
    -->
    </nb-action>
    <nb-action class="user-action" *nbIsGranted="['view', 'user']">
      <nb-user [nbContextMenu]="userMenu" [nbContextMenuTag]="'menu-header'" size="giant"
        [name]="userData?.name + ' ' + userData?.lastname" [title]="user?.cargo" [showInitials]="true"
        [badgeText]="userData?.type_user" badgePosition="bottom start" onlyPicture [badgeStatus]="'primary'">
      </nb-user>
    </nb-action>
  </nb-actions>
  <p-dialog [visible]="showChangePassword" [modal]="true" [header]="'Cambio de contraseña de usuario'"
    [style]="{ width: '440px' }" (onHide)="hidePasswordModal()">
    <div class="row">
      <div class="col-12 my-1">
        <div class="form-control-group">
          <span class="label-with-link">
            <label class="label" for="input-password">Ingrese contraseña anterior:</label>
          </span>
          <nb-form-field>
            <input nbInput fullWidth name="password" [type]="getInputType()" id="input-password"
              placeholder="Contraseña" fieldSize="large" autocomplete="on" [(ngModel)]="oldPassword"
              [required]="true" />
            <button nbSuffix nbButton ghost (click)="toggleShowPassword()" type="button">
              <nb-icon [icon]="showPassword ? 'eye-outline' : 'eye-off-2-outline'" pack="eva" [attr.aria-label]="
                showPassword ? 'hide password' : 'show password'
              ">
              </nb-icon>
            </button>
          </nb-form-field>
        </div>
      </div>
      <div class="col-12 my-1">
        <div class="form-control-group">
          <span class="label-with-link">
            <label class="label" for="input-password">Ingrese nueva contraseña:</label>
          </span>
          <nb-form-field>
            <input nbInput fullWidth name="password" [type]="getInputType()" id="input-password"
              placeholder="Contraseña" fieldSize="large" autocomplete="on" [required]="true"
              [(ngModel)]="newPassword" />
            <button nbSuffix nbButton ghost (click)="toggleShowPassword()" type="button">
              <nb-icon [icon]="showPassword ? 'eye-outline' : 'eye-off-2-outline'" pack="eva" [attr.aria-label]="
                showPassword ? 'hide password' : 'show password'
              ">
              </nb-icon>
            </button>
          </nb-form-field>
        </div>
      </div>
      <div class="col-12 my-1">
        <div class="form-control-group">
          <span class="label-with-link">
            <label class="label" for="input-password">Repita nueva contraseña:</label>
          </span>
          <nb-form-field>
            <input nbInput fullWidth name="password" [type]="getInputType()" id="input-password"
              placeholder="Contraseña" fieldSize="large" autocomplete="on" [(ngModel)]="repeatNewPassword"
              [required]="true" />
            <button nbSuffix nbButton ghost (click)="toggleShowPassword()" type="button">
              <nb-icon [icon]="showPassword ? 'eye-outline' : 'eye-off-2-outline'" pack="eva" [attr.aria-label]="
                showPassword ? 'hide password' : 'show password'
              ">
              </nb-icon>
            </button>
          </nb-form-field>
        </div>
      </div>
      <div class="col-12 text-center my-1">
        <span class="text-danger">
          <ng-container *ngIf="repeatNewPassword !== newPassword">
            <b>Contraseñas no coinciden</b><br>
          </ng-container>
          <ng-container *ngIf="newPassword === oldPassword">
            <b>La contraseña no puede ser la misma que la actual</b>
          </ng-container>
        </span>
      </div>
    </div>

    <ng-template pTemplate="footer">
      <div class="row">
        <div [ngClass]="{ 'text-center': true, 'col-6': true }">
          <button size="small" nbButton status="success" class="mx-1" (click)="changePassword()"
            [disabled]="guardandoCambioDeContraena">
            <nb-icon icon="save-outline" pack="eva"></nb-icon> Cambiar Contraseña
          </button>
        </div>
        <div [ngClass]="{ 'text-center': true, 'col-6': true }">
          <button size="small" nbButton status="danger" class="mx-1" (click)="hidePasswordModal()">
            <nb-icon icon="close-circle-outline" pack="eva"></nb-icon> Cancelar
          </button>
        </div>
      </div>
    </ng-template>
  </p-dialog>
</div>

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../../environments/environment";
import { DeviceDetectorService } from 'ngx-device-detector';
import { map, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PublicService {
  constructor(private http: HttpClient, private deviceService: DeviceDetectorService) { }

  isMobile() {
    return this.deviceService.isMobile();
  }

  isDesktop() {
    return this.deviceService.isDesktop();
  }

  isTablet() {
    return this.deviceService.isTablet();
  }
  AgroupBy(datos: any, id: any, sortBy: any = null, sumar: any = null) {
    const agrupado: any = [];
    const groupBy = (data: any, keyFn: any) => data.reduce((agg: any, item: any) => {
      const group = keyFn(item);
      agg[group] = [...(agg[group] || []), item];
      return agg;
    }, {});

    of(datos).pipe(
      map((data: any) => groupBy(data, (item: any) => this.dataSplit(id, item)))).subscribe(data => {
      const items = Object.keys(data).map(function (personNamedIndex) {
        const person = data[personNamedIndex];
        return person;
      });
      items.forEach((item) => {
        const cabecera = item[0];
        if (sumar) {
          cabecera.total = this.redondear(item.reduce((accumulator: any, current: any) =>
            Number(accumulator) + Number(current[sumar]), 0), 2);
        }
        agrupado.push({cabecera: cabecera, items: item});
      });
      if (sortBy) {
        agrupado.sort((a: any, b: any) => a.cabecera[sortBy] - b.cabecera[sortBy]);
      }
    });
    return agrupado;
  }

  redondear(value: any, decimal: any) {
    return Number(Math.round(Number(value + 'e' + decimal)) + 'e' + decimal * -1);
  }

  dataSplit(field: any, item: any) {
    return field
      .split('.')
      .reduce((value: any, part: any) => value && value.hasOwnProperty(part) ? value[part] : null, item);
  }

  expandNestedProperties(data: any) {
    return data?.map((x: any) => {
      // return this.retroactiva(x);
      const newObject = {}
      this.recursiveAssign(x, newObject);
      return newObject;
    }) || [];
  }

  recursiveAssign(obj: any, result: any = null, currentPath: string = '') {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const newPath = currentPath ? `${currentPath}.${key}` : key;

        if (typeof obj[key] === 'object' && obj[key] !== null) {
          this.recursiveAssign(obj[key], result, newPath);
        }

        result[newPath] = obj[key];
      }
    }
  }

  capitalizeText(text: string, minLength: number = 2): string {
    // Verificar si el texto está vacío o es null
    if (!text) {
      return '';
    }
    text = text.toLowerCase();

    // Dividir el texto en palabras, aplicar la condición y luego unir las palabras
    return text
      .split(' ')
      .map(word => (word.length > minLength ? word.charAt(0).toUpperCase() + word.slice(1) : word))
      .join(' ');
  }
  isAdmin(user: any) {
    return Number(user?.id_type_user) === 1;
  }
}

<div class="h-100 w-100" [nbSpinner]="loading">
  <full-calendar #calendar [options]="calendarOptions" [events]="dispatchList"
    [ngClass]="{'mobile-calendar': !pService.isDesktop(), 'h-100': true}">
    <ng-template #eventContent let-arg>
      <div *ngIf="arg?.view?.type === 'dayGridMonth'" class="fc-daygrid-event-dot" [ngStyle]="{
          'border-color': arg.backgroundColor || 'var(--color-info-default)'
        }"></div>
      <div class="fc-event-time">{{ arg.timeText }}</div>
      <div class="fc-event-title">
        {{ arg.event.title }} <br>{{ arg.event?.extendedProps?.name }}</div>
    </ng-template>
  </full-calendar>
</div>
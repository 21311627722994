import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
    providedIn: 'root'
})

export class AlertService {
    // soundAlert: any = new Audio('./assets/alerta.mp3');
    constructor() {
      // this.soundAlert.load();
    }

    fireAlert(title: string,
        icon: 'success' | 'error' | 'warning' | 'info' | 'question' = 'success',
        content: string | HTMLElement | undefined = undefined,
        html: boolean = false, withSound: boolean = false, functionClickEvent: any = null) {

        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
                if (functionClickEvent) {
                  toast.addEventListener('click', () => {
                    functionClickEvent(Toast);
                  })
                }
            }
        });
        setTimeout(() => {
          const div: any = document.querySelector('.swal2-container');
          if (div) {
            div.style.zIndex = 99999;
          }
        }, 40)
        let fire;
        if (html) {
          fire = Toast.fire({
                icon: icon,
                title: title,
                html: content,
            });
        } else {
          fire = Toast.fire({
                icon: icon,
                title: title,
                text: content ? content?.toString() : undefined,
            });
        }
        return Toast;
    }

    playSound() {
      // this.soundAlert.stop();
      // this.soundAlert.play();
    }
}

import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import { DatosFactura, POSY, configGrafico } from 'src/app/@core/interfaces/datos_factura';
import { DetallesFactura, FirmasFactura } from 'src/app/@core/interfaces/detalles_factura';
import { InfoFactura } from 'src/app/@core/interfaces/info_factura';
import { PrintService } from 'src/app/@core/services/print.service';

@Component({
  selector: 'ngx-invoice-layout',
  templateUrl: './invoice-layout.component.html',
  styleUrls: ['./invoice-layout.component.scss'],
})
export class InvoiceLayoutComponent implements OnInit, AfterViewInit {
  invoiceIds: string[];
  tipoFactura: any;
  @Input() titulo: any;
  @Input() id!: any;
  @Input() titulo_id: any = '#';
  @Input() fecha: any;
  @Input() datos: any = [];
  @Input() detalles: any = [];
  @Input() firmas: any = [];
  info: any;
  @Input() descripcion: any;
  @Input() load: any;
  @Input() grafico: any;
  @Input() tituloFecha = 'Fecha:';
  @Input() modeloTabla: any = 1;
  @Input() title_footer: any;
  @Input() details_footer: any = [];
  @Input() cuerpo: any;
  @Input() margenesModel3: boolean = true;
  @Input() classHederTable: string | null = null;
  @Input() alignText: string = 'center';
  @Input() classTitleTable: string | null= null;
  numCopias: number = 1;
  pos = POSY;
  @Input() titulo_descripcion: string = 'Descripción:';
  @Input() borderTable: boolean = true;
  @Input() isProforma: boolean = false;
  date = new Date();

  constructor(route: ActivatedRoute, private printService: PrintService) {
    // if (this.load === true) {
    this.tipoFactura = route.snapshot.params['tipo'];
    this.invoiceIds = route.snapshot.params['ids'].split(',');
    // }
  }

  ngOnInit(): void {
    console.log(this.firmas);
    console.log(this.datos);
    const spinnerContent: any = document.getElementsByClassName('spinner');
    if (spinnerContent) {
      spinnerContent[0]['style'].display = 'none';
    }
    this.genData();
  }

  async genData() {
    this.detalles = this.printService.currentDetallesFactura;
    this.datos = this.printService.currentDatosFactura;
    this.info = this.printService.currentInfoFactura;
    this.firmas = this.printService.currentFirmasFactura;
    this.titulo = this.info?.titulo;
    this.id = this.info?.id;
    this.titulo_id = this.info?.titulo_id;
    this.descripcion = this.info?.descripcion;
    this.grafico = this.printService.currentGraficoFactura;
    this.modeloTabla = this.printService.currentModeloTablaFactura;
    this.title_footer = this.printService.currentTitleFooterFactura;
    this.details_footer = this.printService.currentDetailFooterFactura;
    this.cuerpo = this.printService?.currentBody;
    this.numCopias = this.info?.numCopias;
    this.margenesModel3 = this.info?.margenesModel3;
    this.alignText = this.info?.alignText;
    this.classTitleTable = this.info?.classTitleTable;
    this.classHederTable = this.info?.classHederTable;
    this.titulo_descripcion = this.info?.titulo_descripcion;
    this.borderTable = this.info?.borderTable;
    await this.getNumberPage();
    this.tituloFecha = this.info?.tituloFecha;
    this.fecha = this.info?.fecha;
    // console.log('Informacion de fecha', this.info);
    console.log(this.firmas);
    if (this.firmas?.length > 0) {
      if (this.firmas.filter((item: any) => item?.id_storage).length > 0) {
        setTimeout(async () => {
          await this.printService.onDataReady();
        }, 1500);
      } else {
        await this.printService.onDataReady();
      }
    } else {
      await this.printService.onDataReady();
    }
    this.isProforma = this.info?.isProforma;
    // console.log('Informacion de fecha', this.info);
  }

  getNumberPage() {
    console.log(document.querySelectorAll('.footer-firma'));
    const totalPages = document.querySelectorAll('.invoice').length;
    document.documentElement.style.setProperty('--total-pages', String(totalPages));
    console.log(totalPages);
    const signature: any = document.getElementsByClassName('signature');
    if (signature.length >= 1) {
      for (let i = 0; i < signature.length; i++) {
        console.log(signature[i]);
        signature[i]['style']['font_family'] = '\'Whisper\', serif !important;';
      }
      // signature[0]['style']['font_family'] = '\'Monique\', serif !important;';
      console.log(signature);
    }
    // console.log(totalPages);
  }

  ngAfterViewInit() {
    const totalPages = document.querySelectorAll('.footer-firma').length;
    document.documentElement.style.setProperty('--total-pages', String(totalPages));
  }
}

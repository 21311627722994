import { DispatchService } from './../../../../@core/services/dispatch.service';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import esLocale from '@fullcalendar/core/locales/es';
import { PublicService } from './../../../../@core/services/public.service';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CalendarOptions } from '@fullcalendar/core';

@Component({
  selector: 'app-view-fleet-date-dispatch',
  templateUrl: './view-fleet-date-dispatch.component.html',
  styleUrls: ['./view-fleet-date-dispatch.component.scss']
})
export class ViewFleetDateDispatchComponent implements OnInit {


  _currentDateString: string = '';
  get currentDateString(): string {
    return this._currentDateString;
  }

  @Input() set currentDateString(value: string) {
    this._currentDateString = value;
  }


  _currentSelected: any = null;
  get currentSelected(): any {
    return this._currentSelected;
  }

  @Input() set currentSelected(value: any) {
    this._currentSelected = value;
    this.getEvents();
  }

  _currentDate: any = null;
  get currentDate(): any {
    return this._currentDate;
  }

  @Input() set currentDate(value: any) {
    this._currentDate = value;
    this.setDateCalendar();
  }

  calendarOptions!: CalendarOptions;
  isDesktop = false;
  dispatchList: any = [];
  @ViewChild('calendar') calendar!: any;
  loading = false;

  constructor(public pService: PublicService,
    private dispatchS: DispatchService) {

  }

  ngOnInit(): void {
    this.isDesktop = this.pService.isDesktop();
    const rigth = ''; // 'listWeek';
    const initialView = 'listWeek';
    const leftButtons = ''; // 'addDispatchBtn';
    const object: CalendarOptions = {
      locales: [esLocale],
      plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
      customButtons: {
        addDispatchBtn: {
          text: 'Agregar Despacho',
        }
      },
      noEventsText: 'No existen despachos para mostrar',
      headerToolbar: {
        left: leftButtons,
        center: "title",
        right: rigth
      },
      locale: 'es',
      initialView: initialView,
      droppable: false,
      editable: false,
      eventDrop: () => {},
      eventResize:() => {},
      // eventClick: this.clickEvent.bind(this),
      dateClick: () => {},
      // eventMouseEnter: this.mostrarTooltip.bind(this),
      eventInteractive: true,
      viewDidMount: () => {},
    }
    this.calendarOptions = object;
  }

  setDateCalendar() {
    this.dispatchList = [];
    console.log('date', this.currentDate);
    if (this.calendar) {
      this.calendar.getApi().gotoDate(this._currentDate);
    }
  }

  getEvents() {
    this.dispatchList = [];
    this.loading = true;
    try {
      if (this.currentSelected.id && this.currentDateString) {
        this.dispatchS.getByFleetIdAndWeek(this.currentSelected.id, this.currentDateString).subscribe({
          next: (resp: any) => {
            this.dispatchList = [];
            if (resp.success && resp.data) {
              this.dispatchList = resp.data || [];
            }
            console.log('resp Events', resp);
          },
          error: (err: any) => {
            console.log('err', err);
            this.loading = false;
          },
          complete: () => {
            console.log('completar');
            this.loading = false;
          }
        });
      } else {
        this.loading = false;
      }
    } catch (error) {
      this.loading = false;
    }
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class DispatchService {
  PATHAPI: string = 'api/dispatch';

  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<any>(environment.apiURL + this.PATHAPI);
  }

  getById(id_sale: any) {
    return this.http.get<any>(environment.apiURL + this.PATHAPI + '/' + id_sale);
  }

  save(dispatch: any, items: any[] = []) {
    const data = { dispatch, items }
    return this.http.post<any>(environment.apiURL + this.PATHAPI + '/create', data);
  }

  update(dispatch: any, items: any[] = []) {
    const data = { dispatch, items: items && items.length > 0 ? items : null }
    return this.http.patch<any>(environment.apiURL + this.PATHAPI + '/update', data);
  }
  updateStatus(id_dispatch: any, status: any, id_usuario_modificador: any) {
    return this.http.patch<any>(`${environment.apiURL}${this.PATHAPI}/changeDispatchStateById`, {id_dispatch, status, id_usuario_modificador});
  }

  delete(id: any) {
    return this.http.delete<any>(environment.apiURL + this.PATHAPI + '/' + id);
  }

  getByFleetIdAndWeek(id_fleet: any, date: any) {
    return this.http.get<any>(environment.apiURL + this.PATHAPI + '/getByFleetIdAndWeek/' + id_fleet + '/' + date);
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class FleetService {
  PATHAPI: string = 'api/fleet';

  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<any>(environment.apiURL + this.PATHAPI);
  }
  getAllFleetsDisponible(date_start: any, date_end: any) {
    return this.http.get<any>(environment.apiURL + this.PATHAPI + `/disp/${date_start}/${date_end}`);
  }
  save(data: any) {
    return this.http.post<any>(environment.apiURL + this.PATHAPI + '/create', data);
  }
  update(data: any) {
    return this.http.patch<any>(environment.apiURL + this.PATHAPI + '/update', data);
  }
  delete(id: any) {
    return this.http.delete<any>(environment.apiURL + this.PATHAPI + '/' + id);
  }
  getByDateDispatch(date: any) {
    return this.http.get<any>(`${environment.apiURL}${this.PATHAPI}/getByDateDispatch/${date}`);
  }
}

export const environment = {
  apiURL: 'https://apiventas.aquaher.com/',
  // apiURL: 'http://localhost:3700/',
  apiWhatsapp: 'https://baileys.aquaher.com',
  apiMQTT: 'https://api.aquaher.com',
  firebaseConfig: {
    apiKey: 'AIzaSyBL3IIPgmO7_Nxdorddx_5Xcy9SayNwysU',
    authDomain: 'ventas-c6fdb.firebaseapp.com',
    databaseURL: 'https://ventas-c6fdb-default-rtdb.firebaseio.com',
    projectId: 'ventas-c6fdb',
    storageBucket: 'ventas-c6fdb.appspot.com',
    messagingSenderId: '709720113451',
    appId: '1:709720113451:web:d2fc906cf4652e7877056e',
    measurementId: 'G-JN3NRKDNZX',
    vapidKey:
      'BCxnyQA1SpmbVQI7HWWGIIMHDwjolAdV-VgJ-SwBAS6sLQ_vTBNEZam2u8ihUhlWOekKt6EGABN6zSuQ9VMj5B8',
  },
  defaultConfigCKEditor: {
    toolbar: [
      'undo',
      'redo',
      '|',
      'heading',
      '|',
      'bold',
      'italic',
      '|',
      'link',
      'uploadImage',
      'insertTable',
      'blockQuote',
      'mediaEmbed',
      '|',
      'bulletedList',
      'numberedList',
      'outdent',
      'indent',
    ],
  },
};

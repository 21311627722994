import { AuthService } from './../../../@core/services/auth.service';
import { firstValueFrom } from 'rxjs';
import { NotificationService } from './../../../@core/services/notification.service';
import { Component, AfterViewInit, OnInit } from '@angular/core';

@Component({
  selector: 'app-notifications-view',
  templateUrl: './notifications-view.component.html',
  styleUrls: ['./notifications-view.component.scss']
})
export class NotificationsViewComponent implements AfterViewInit, OnInit{

  loadingNotif: boolean = false;
  notifications: any[] = [];
  locationHost = window.location?.origin + '/#';
  constructor(private notificationService: NotificationService, private authService: AuthService) {

  }
  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    console.log('notificationsList', this.notifications)
    console.log('location', this.locationHost)
  }

  testNotify(notification: any) {
    console.log(notification)
  }

  async openNoti(notification: any) {
    notification.readed = 1;
    const data = this.authService.getUserData();
    notification.id_usuario_modificador = data.id || 0;
    const service = await this.notificationService.updateNotification(notification);
    const result = firstValueFrom(service);
  }

}

<div class="invoice" *ngFor="let invoice of invoiceIds">
  <!--  class="p-grid mb-5 mt-5" style="margin-top: 15%" [ngClass]="{'mt-5': isProforma}"-->
  <div class="p-grid mb-5" [style.margin-top]="isProforma ? '15%' : ''" id="factura_{{i}}"
       *ngFor="let testing of [].constructor(numCopias); let i = index">
    <div class="p-col">
      <div>
        <div id="factura-content">
          <div class="invoice">
            <div class="invoice-header row">
<!--              <div class="d-flex justify-content-between">-->
                <div class="col invoice-company">
                  <div class="invoice-title text-center" *ngIf="titulo && isProforma" [innerHTML]="titulo"></div>
                  <img id="invoice-logo" src="assets/aquaher.svg" height="100"
                       alt="Logo Bilbosa" class="logo-image" *ngIf="!isProforma">
                  <!--              <div class="company-name">YOUR COMPANY</div>-->
                  <!--              <div>9137 3rd Lane California City</div>-->
                  <!--              <div>CA 93504, U.S.A.</div>-->
                </div>
                <div class="col d-flex flex-column align-content-end justify-content-center">
                  <div class="invoice-title text-center" *ngIf="titulo && !isProforma" [innerHTML]="titulo"></div>
                  <div class="invoice-to-info align-items-center">
                    <div class="row justify-content-end">
                      <div class="col" *ngIf="titulo && titulo_id">
                        <div class="invoice-details">
                          <div class="invoice-label" *ngIf="titulo_id">{{titulo_id}}:</div>
                          <div class="invoice-value" *ngIf="id">{{id}}</div>
                        </div>
                      </div>
                      <div
                        class="col p-0" *ngIf="fecha">
                        <div class="invoice-details">
                          <div class="invoice-label" *ngIf="fecha">{{tituloFecha}}</div>
                          <div class="invoice-value" *ngIf="fecha">{{fecha}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
<!--                </div>-->
              </div>
            </div>
            <div class="invoice-to" *ngIf="detalles">
              <!--            <div class="bill-to">Detalles</div>-->
              <div class="invoice-to-info">
                <div class="row">
                  <div class="invoice-info-col col mx-0" *ngFor="let d of detalles; let i = index;"
                       [ngClass]="{'col-12': detalles.length === 1, 'col-6': detalles.length === 2,
                        'col-4': detalles.length === 3}">
                    <div class="invoice-details" *ngFor="let c of d">
                      <div class="invoice-label" [innerHTML]="c.label"></div>
                      <div class="invoice-value" [innerHTML]="c.value"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row p-1" *ngIf="grafico?.src && grafico.posy === pos.ARRIBA">
              <div class="col-12 d-flex justify-content-{{grafico.posx}}">
                <img [src]="grafico?.src"
                     [alt]="grafico?.alt" class="img-responsive">
              </div>
            </div>
            <div class="invoice-items" *ngIf="datos && modeloTabla == 1">
              <div class="row d-flex">
                <div class="p-1" [ngClass]="'col-' + (12 / (d?.numeroColumnas || 1))"
                     *ngFor="let d of datos; let last = last;">

                  <!-- Detalles por encima de tabla de modelo 1 -->
                  <div class="invoice-to mt-3" *ngIf="d?.detallesTable">
                    <div class="invoice-to-info">
                      <div class="row">
                        <div class="col-12 text-center" *ngIf="d?.titleDetalleTable">
                          <label class="bg-azul-bilbosa w-100" [innerHTML]="d.titleDetalleTable"></label>
                        </div>
                        <div class="invoice-info-col col mx-0"
                             *ngFor="let detailTable of d?.detallesTable; let i = index;"
                             [ngClass]="{'col-12': d?.detallesTable.length === 1, 'col-6': d?.detallesTable.length === 2,
                              'col-4': d?.detallesTable.length >= 3}">
                          <div class="invoice-details" *ngFor="let cT of detailTable">
                            <div class="invoice-label" [innerHTML]="cT.label"></div>
                            <div class="invoice-value" [innerHTML]="cT.value"></div>
                          </div>
                        </div>
                        <div *ngIf="d?.comentarioDetalleTable" class="col-12 mx-0 text-left">
                          <div class="invoice-details">
                            <div class="invoice-label" [innerHTML]="d.comentarioDetalleTable?.label"></div>
                            <div class="invoice-value" [innerHTML]="d.comentarioDetalleTable?.value"></div>
                          </div>
                        </div>
                        <div *ngIf="d?.observacionDetalleTable" class="col-12 mx-0 text-left">
                          <div class="invoice-details">
                            <div class="invoice-label" [innerHTML]="d.observacionDetalleTable.label"></div>
                            <div class="invoice-value" [innerHTML]="d.observacionDetalleTable.value"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <table class="table {{borderTable ? 'table-bordered' : ''}}">
                    <thead>
                    <tr *ngIf="d?.titulo_tabla">
                      <th [class]="d?.classTitleTable || classTitleTable ? (d?.classTitleTable || classTitleTable) : ''"
                          [colSpan]="d?.colspan || d?.cabecera.length" class="text-center" [innerHTML]="d?.titulo_tabla">
                      </th>
                    </tr>
                    <tr *ngIf="d?.cabecera.length > 0" [class]=" classHederTable ? classHederTable : ''">
                      <th class="text-{{alignText}}" [colSpan]="c?.colspan" [rowSpan]="c?.rowspan"
                          [style.width]="c?.width"
                          *ngFor="let c of d.cabecera" [innerHTML]="c?.value || c">

                      </th>
                    </tr>
                    </thead>
                    <tbody *ngIf="d?.datos.length > 0">
                      <tr *ngFor="let v of d.datos">
                        <td [attr.colspan]="val?.colspan" [attr.rowspan]="val?.rowspan" [style.width]="val?.width"
                            class="{{(val?.type === 'number' ? 'text-right': ('text-' + alignText))}}"
                            *ngFor="let val of v" [innerHTML]="val?.value || val"></td>
                      </tr>
                    </tbody>
                    <tfoot class="w-100" *ngIf="d?.footer?.length > 0">
                    <tr *ngFor="let footer of d?.footer;">
                      <th [attr.colspan]="val?.colspan" [attr.rowspan]="val?.rowspan" [style.width]="val?.width"
                          class="{{(val?.type === 'number' ? 'text-right': ('text-' + alignText))}}"
                          *ngFor="let val of footer">
                        <b style="font-size: 11px" [innerHTML]="val?.value"></b>
                      </th>
                    </tr>
                    </tfoot>
                  </table>
                </div>
                <div class="invoice-summary col-12" *ngIf="descripcion">
                  <div class="invoice-notes"><b>{{titulo_descripcion || 'Descripción:'}}</b>
                    <div [innerHTML]="descripcion"></div>
                  </div>
                  <!--            <div>-->
                  <!--              <div class="invoice-details">-->
                  <!--                <div class="invoice-label">SUBTOTAL</div>-->
                  <!--                <div class="invoice-value">$332.00</div>-->
                  <!--                <div class="invoice-label">VAT</div>-->
                  <!--                <div class="invoice-value">0</div>-->
                  <!--                <div class="invoice-label">TOTAL</div>-->
                  <!--                <div class="invoice-value">$332.00</div>-->
                  <!--              </div>-->
                  <!--            </div>-->
                </div>
                <div class="col-12 d-flex justify-content-{{grafico.posx}}"
                     *ngIf="grafico?.src && grafico.posy === pos.ABAJO">
                  <img [src]="grafico?.src"
                       [alt]="grafico?.alt" class="img-responsive">
                </div>
                <div class="col-12" *ngIf="firmas">
                  <table>
                    <tfoot class="h-100 foot-firmas">
                    <tr class="border-0 w-100">
                      <td>
                        <div id="footer-firma" class="footer-firma d-flex justify-content-center w-100" *ngIf="firmas">
                          <div class="row">
                            <div class="text-center p-2" *ngFor="let f of firmas; let i = index;"
                                 [ngClass]="{'col-12': firmas?.length === 1,
                     'col-6': firmas?.length === 2,
                     'col-4': firmas?.length === 3,
                     'col-3': firmas?.length === 4,
                     'col-2': firmas?.length === 5,
                     'col-1': firmas?.length >= 6
                     }">
                              <div class="text-center">
                                <p class="signature">{{f?.value ? f?.value : '&nbsp;'}}</p>
                                <div class="d-flex justify-content-center mb-5">
                                  <img *ngIf="f?.image" [src]="f?.image" alt="Firma" width="120" height="50"
                                       class="img-responsive img-firma position-absolute" [id]="f?.id_storage">
                                </div>
                                <div class="d-flex flex-column">
                                  <b>_________________________________</b>
                                  <b>{{f?.label}}</b>
                                  <small *ngIf="!isProforma">{{f?.nombres}}</small>
                                  <label>&nbsp;</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
            <div class="invoice-items" *ngIf="datos && modeloTabla == 2">
              <div class="row d-flex">
                <div class="p-1 col-12 mb-4 mt-4"
                     *ngFor="let d of datos; let last = last;">
                  <table>
                    <thead>
                    <tr *ngIf="d?.titulo_tabla">
                      <th [colSpan]="d?.cabecera.length" class="text-center"> {{d?.titulo_tabla}}</th>
                    </tr>
                    <tr *ngIf="d?.cabecera.length > 0">
                      <th class="text-center" [colSpan]="i === 0 ? 4 : 1" *ngFor="let c of d.cabecera; let i = index"
                          [innerHTML]="c"></th>
                    </tr>
                    </thead>
                    <tbody *ngIf="d?.datos.length > 0">
                    <tr *ngFor="let v of d.datos">
                      <td [ngClass]="b === 0 ? 'text-left': 'text-rigth' "
                          [colSpan]="val?.colspan ? val.colspan : b === 0 ? 4 : 1" *ngFor="let val of v; let b = index"
                          [innerHTML]="val?.value || val"></td>
                    </tr>
                    </tbody>
                    <tfoot class="w-100" *ngIf="d?.footer?.length > 0">
                    <tr *ngFor="let footer of d.footer;">
                      <th [attr.colspan]="val?.colspan" [attr.rowspan]="val?.rowspan"
                          [ngClass]="val?.type === 'number' ? 'text-right': 'text-center' " *ngFor="let val of footer">
                        <b style="font-size: 11px" [innerHTML]="val?.value"></b></th>
                    </tr>
                    </tfoot>
                  </table>
                </div>

                <div class="invoice-summary col-12" *ngIf="descripcion">
                  <div class="invoice-notes"><b>Descripción</b>
                    <div>{{descripcion}}</div>
                  </div>
                </div>
                <div class="col-12 d-flex justify-content-{{grafico.posx}}"
                     *ngIf="grafico?.src && grafico.posy === pos.ABAJO">
                  <img [src]="grafico?.src"
                       [alt]="grafico?.alt" class="img-responsive">
                </div>

                <div class="invoice-to col-12" *ngIf="details_footer?.length > 0">
                  <div class="invoice-to-info row">
                    <div class="col-12 row">
                      <div *ngIf="title_footer?.trim() !== ''" class="col-12 text-center">
                        <b>{{title_footer}}</b>
                      </div>
                      <div class="invoice-info-col col mx-0" *ngFor="let d of details_footer; let i = index;"
                           [ngClass]="{'col-12': details_footer.length === 1, 'col-6': details_footer.length === 2,
                            'col-4': details_footer.length >= 3}">
                        <div class="invoice-details row" *ngFor="let c of d">
                          <div class="invoice-label col-6 text-right textoFooter" [innerHTML]="c.label"></div>
                          <div class="invoice-value col-6 text-left textoFooter">{{c.value}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12" *ngIf="firmas">
                  <table>
                    <tfoot style="height: 100%" class="foot-firmas">
                    <tr class="border-0 w-100">
                      <td>
                        <div id="footer-firma" class="footer-firma d-flex justify-content-center w-100" *ngIf="firmas">
                          <div class="row">
                            <div class="text-center p-2" *ngFor="let f of firmas; let i = index;"
                                 [ngClass]="{'col-12': firmas?.length === 1,
                     'col-6': firmas?.length === 2,
                     'col-4': firmas?.length === 3,
                     'col-3': firmas?.length >= 4}">
                              <div class="text-center">
                                <p class="signature">{{f?.value ? f?.value : '&nbsp;'}}</p>
                                <div class="d-flex justify-content-center mb-5">
                                  <img *ngIf="f?.image" [src]="f?.image" alt="Firma" width="120" height="50"
                                       class="img-responsive img-firma position-absolute" [id]="f?.id_storage">
                                </div>
                                _________________________________<br>
                                <!-- {{f?.label}} -->
                                <!--                                <div *ngIf="!isProforma">-->
                                <label [innerHTML]="f?.label"></label>
                                <!--                                </div>-->
                                <div *ngIf="isProforma">
                                  <label>{{date | date: 'shortDate'}}</label>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
            <div class="invoice-items" *ngIf="cuerpo && modeloTabla == 3">
              <div class="row d-flex">
                <div class="m-5">

                </div>
                <div class="col-12">
                  <div class="row">
                    <div class="col-2" *ngIf="margenesModel3"></div>
                    <div [class]="margenesModel3 ? 'col-8' : 'col-12'" [innerHTML]="cuerpo">
                    </div>
                    <div class="col-2" *ngIf="margenesModel3"></div>
                  </div>
                </div>
                <div class="m-5">
                </div>

                <div class="invoice-summary col-12" *ngIf="descripcion">
                  <div class="invoice-notes"><b>Descripción</b>
                    <div>{{descripcion}}</div>
                  </div>
                </div>
                <div class="col-12 d-flex justify-content-{{grafico.posx}}"
                     *ngIf="grafico?.src && grafico.posy === pos.ABAJO">
                  <img [src]="grafico?.src"
                       [alt]="grafico?.alt" class="img-responsive">
                </div>

                <div class="invoice-to col-12" *ngIf="details_footer?.length > 0">
                  <div class="invoice-to-info row">
                    <div class="col-12 row">
                      <div *ngIf="title_footer?.trim() !== ''" class="col-12 text-center">
                        <b>{{title_footer}}</b>
                      </div>
                      <div class="invoice-info-col col mx-0" *ngFor="let d of details_footer; let i = index;"
                           [ngClass]="{'col-12': details_footer.length === 1, 'col-6': details_footer.length === 2,
                            'col-4': details_footer.length >= 3}">
                        <div class="invoice-details" *ngFor="let c of d">
                          <div class="invoice-label" [innerHTML]="c.label"></div>
                          <div class="invoice-value">{{c.value}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12" *ngIf="firmas">
                  <table>
                    <tfoot style="height: 100%" class="foot-firmas">
                    <tr class="border-0 w-100">
                      <td>
                        <div id="footer-firma" class="footer-firma d-flex justify-content-center w-100" *ngIf="firmas">
                          <div class="row">
                            <div class="text-center p-2" *ngFor="let f of firmas; let i = index;"
                                 [ngClass]="{'col-12': firmas?.length === 1,
                     'col-6': firmas?.length === 2,
                     'col-4': firmas?.length === 3,
                     'col-3': firmas?.length >= 4}">
                              <div class="text-center">
                                <p class="signature">{{f?.value ? f?.value : '&nbsp;'}}</p>
                                <div class="d-flex justify-content-center mb-5">
                                  <img *ngIf="f?.image" [src]="f?.image" alt="Firma" width="120" height="50"
                                       class="img-responsive img-firma position-absolute" [id]="f?.id_storage">
                                </div>
                                _________________________________<br>
                                <!-- {{f?.label}} -->
                                <!--                                <div *ngIf="!isProforma">-->
                                <label [innerHTML]="f?.label"></label>
                                <!--                                </div>-->
                                <div *ngIf="isProforma">
                                  <br>
                                  <label>{{date | date: 'shortDate'}}</label>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                    </tfoot>
                  </table>
                </div>
                <div class="m-4">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

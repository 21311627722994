import { UserService } from './user.service';
import { AlertService } from './alert.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, firstValueFrom } from 'rxjs';
import { AuthService } from './auth.service';
import { ModuleService } from './module.service';

@Injectable({
    providedIn: 'root'
})

export class AuthGuardService implements CanActivate  {

    constructor(private router: Router, private alertS: AlertService,
        private userService: UserService, private authService: AuthService,
        private moduleService: ModuleService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        const url: any = route.url;
        return this.checkUserLogin(route, url);
    }

    async checkUserLogin(route: ActivatedRouteSnapshot, url: any) {
        // console.log('URL', url);
        // console.log(route);
        const rolValidate: any = route.data;
        // localStorage.setObject('userData', {user:'test', type: 'admin'});
        let userDataLocal = this.authService.getUserData();
        console.log('userDataLocal', userDataLocal)
        if (userDataLocal && userDataLocal.id && Number(userDataLocal?.id) > 0)
        {
            const id_data = Number(userDataLocal?.id)  || 0;
            const refreshData = await firstValueFrom(this.userService.getByID(id_data));
            if (refreshData && refreshData.data && refreshData.data.id === userDataLocal.id) {
                userDataLocal = refreshData.data;
                // localStorage.setObject('userData', refreshData);
                this.authService.setUserData(refreshData.data);
            }
            if (rolValidate && rolValidate?.rolespermission) {
                const roles = rolValidate.rolespermission;
                if (roles.includes(userDataLocal.Type)) {
                    return true
                }
                this.router.navigate(['/']);
                this.alertS.fireAlert('No se pudo acceder a la ruta', 'error','Usuario no autorizado');
                return false;
            }
            setTimeout(async () => {
              if (refreshData?.data?.id_type_user && Number(refreshData?.data?.id_type_user) > 0) {
                const refreshModules = await firstValueFrom(this.moduleService.getByTypeUser(refreshData?.data?.id_type_user));
                console.log('refreshModules', refreshModules);
                if (refreshModules.success && refreshModules.data && refreshModules.data.length > 0) {
                  this.authService.setUserModules(refreshModules.data);
                }
              }
            }, 300)
            return true;
        } else {
          this.router.navigate(['/auth/login']);
          // this.alertS.fireAlert('No se pudo acceder a la ruta', 'error','Usuario no autorizado');
          return false;
        }
    }
}

import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { firstValueFrom, map, mergeMap, of } from 'rxjs';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { Socket, SocketIoConfig } from 'ngx-socket-io';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  PATHAPI: string = 'api/token_notification';
  PATHAPINOT: string = 'api/notification';
  config: SocketIoConfig = {
    url: environment.apiURL,
  };

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private afMessaging: AngularFireMessaging,
    private socket: Socket
  ) {
    this.socket = new Socket(this.config);
  }

  requestPermission() {
    const userData = this.auth.getUserData();
    console.log('userData', userData);
    if (userData && Number(userData?.id ?? 0) > 0) {
      this.afMessaging.requestToken
        .pipe(mergeMap(() => this.afMessaging.tokenChanges))
        .subscribe(
          async (token) => {
            console.log('Permission granted! Save to the server!', token);
            if (token) {
              const body = {
                id_user: userData?.id,
                token: token,
                id_usuario_creador: userData?.id,
                id_usuario_modificador: userData?.id,
              };
              const resp = await this.http.patch<any>(
                environment.apiURL + this.PATHAPI + '/update_by_token_and_user',
                body
              );
              const result = await firstValueFrom(resp);
              console.log('result token', result);
            }
          },
          (error) => {
            console.error(error);
          }
        );
    }
  }

  deleteToken() {
    return new Promise((resolve, reject) => {
      this.afMessaging.getToken
        .pipe(mergeMap((token: any) => this.afMessaging.deleteToken(token)))
        .subscribe({
          next: async (token) => {
            console.log('Token deleted!', token);
            const resp = await this.http.delete<any>( environment.apiURL + this.PATHAPI + '/' + token);
            const result = await firstValueFrom(resp);
            console.log('result delete token', result);
            resolve('token delete')
          },
          error: (error) => {
            resolve(error)
          }
        });
    })
  }

  getNotificationsByUser(id_user: any) {
    return this.http.get<any>(
      environment.apiURL + this.PATHAPINOT + '/by_user/' + id_user
    );
  }

  readNotification() {
    const userData = this.auth.getUserData();
    console.log('userData', userData);
    if (userData && Number(userData?.id ?? 0) > 0) {
      const topic = 'notification_' + userData?.id;
      this.socket.ioSocket.io.opts.query = { topic };
      return this.socket.fromEvent(topic);
    }
    return false;
  }

  updateNotification(notification: any) {
    return this.http.patch<any>(environment.apiURL + this.PATHAPINOT + '/update', notification);
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../../environments/environment";
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    PATHAPI: string = 'api/user';
    public currentUser!: Observable<any>;
    private currentUserSubject!: BehaviorSubject<any>;
    public currentUserModules!: Observable<any>;
    private currentUserModulesSubject!: BehaviorSubject<any>;

    constructor(private http: HttpClient) {
      this.currentUserSubject = new BehaviorSubject<any>(localStorage.getObject('userData') || {});
      this.currentUser = this.currentUserSubject.asObservable();
      this.currentUserModulesSubject = new BehaviorSubject<any>(localStorage.getObject('userModules') || {});
      this.currentUserModules = this.currentUserModulesSubject.asObservable();
    }

    login(user: string, password: string) {
        const body = {
            email: user,
            password: password,
        }
        return this.http.post<any>(environment.apiURL + this.PATHAPI + '/login', body);
    }

    setUserData(userData: any) {
        console.log('setUserData')
        localStorage.setObject('userData', userData);
        console.log(this.getUserData())
        this.currentUserSubject.next(this.getUserData());
        return true;
    }

    setUserModules(userModule: any) {
      localStorage.setObject('userModules', userModule);
      this.currentUserModulesSubject.next(this.getUserModule());
      return true;
    }

    isAdmin() {
        const userData = localStorage.getObject('userData');
        if (userData && userData.Type && userData.Type === 'admin') {
            return true;
        }
        return false;
    }

    getUserData() {
        return localStorage.getObject('userData');
    }

    getUserModule() {
      return localStorage.getObject('userModules');
    }
}

import { InfoFactura } from './../interfaces/info_factura';
import { DatosFactura, configGrafico } from './../interfaces/datos_factura';
import { DetallesFactura, FirmasFactura } from './../interfaces/detalles_factura';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PrintService {
  isPrinting = false;
  public currentDetalle!: Observable<DetallesFactura[][]>;
  public currentDetalleSubject!: BehaviorSubject<DetallesFactura[][]>;
  public currentDatos: Observable<DatosFactura[]>;
  public currentDatosSubject: BehaviorSubject<DatosFactura[]>;
  public currentInfo: Observable<InfoFactura | null>;
  public currentInfoSubject: BehaviorSubject<InfoFactura | null>;
  public currentFirmas: Observable<FirmasFactura[] | null>;
  public currentFirmasSubject: BehaviorSubject<FirmasFactura[] | null>;
  public currentGrafico: Observable<any | null>;
  public currentGraficoSubject: BehaviorSubject<configGrafico | null>;
  public currentDetailFooter: Observable<DetallesFactura[][] | null>;
  public currentDetailFooterSubject: BehaviorSubject<DetallesFactura[][] | null>;
  public currentTitleFooter!: string | null;
  public currentModeloTabla!: number | null;
  public currentBody!: string | null;

  constructor(private router: Router) {
    this.currentDetalleSubject = new BehaviorSubject<DetallesFactura[][]>([]);
    this.currentDatosSubject = new BehaviorSubject<DatosFactura[]>([]);
    this.currentInfoSubject = new BehaviorSubject<InfoFactura | null>(null);
    this.currentFirmasSubject = new BehaviorSubject<FirmasFactura[] | null>(null);
    this.currentGraficoSubject = new BehaviorSubject<configGrafico | null>(null);
    this.currentDetailFooterSubject = new BehaviorSubject<DetallesFactura[][] | null>(null);
    this.currentInfo = this.currentInfoSubject.asObservable();
    this.currentDatos = this.currentDatosSubject.asObservable();
    this.currentDetalle = this.currentDetalleSubject.asObservable();
    this.currentFirmas = this.currentFirmasSubject.asObservable();
    this.currentGrafico = this.currentGraficoSubject.asObservable();
    this.currentDetailFooter = this.currentDetailFooterSubject.asObservable();
  }

  public get currentDetallesFactura(): DetallesFactura[][] {
    return this.currentDetalleSubject.value;
  }
  public get currentInfoFactura(): InfoFactura | null {
    return this.currentInfoSubject.value;
  }
  public get currentDatosFactura(): DatosFactura[] {
    return this.currentDatosSubject.value;
  }
  public get currentFirmasFactura(): FirmasFactura[] | null {
    return this.currentFirmasSubject.value;
  }
  public get currentGraficoFactura(): configGrafico | null {
    return this.currentGraficoSubject.value;
  }
  public get currentDetailFooterFactura(): DetallesFactura[][] | null {
    return this.currentDetailFooterSubject.value;
  }
  public get currentTitleFooterFactura(): string | null {
    return this.currentTitleFooter;
  }
  public get currentModeloTablaFactura(): number | null {
    return this.currentModeloTabla;
  }
  public get currentTitleBody(): string | null {
    return this.currentBody;
  }

  printDocument(documentName: string, documentData: string[], infoFactura: InfoFactura,
    detalleFactura: DetallesFactura[][], datosFactura: DatosFactura[],
    firmasFactura: FirmasFactura[] = [], grafico: configGrafico,
    details_footer: DetallesFactura[][], title_footer: string, modeloTabla: number, body: string) {
    // this.ss.set('detalleFactura', detalleFactura);
    // this.ss.set('datosFactura', datosFactura);
    // this.ss.set('infoFactura', infoFactura);
    this.currentDetalleSubject.next(detalleFactura);
    this.currentDatosSubject.next(datosFactura);
    this.currentInfoSubject.next(infoFactura);
    this.currentFirmasSubject.next(firmasFactura);
    this.currentGraficoSubject.next(grafico);
    this.currentDetailFooterSubject.next(details_footer);
    this.isPrinting = true;
    this.currentTitleFooter = title_footer;
    this.currentModeloTabla = modeloTabla;
    this.currentBody = body;
    if (infoFactura?.viewDateBottom === false) {
      const navigationExtras = { queryParams: { viewDate: false } };
      this.router.navigate(['/',
        {
          outlets: {
            'print': ['print', documentName, documentData.join()],
          },
        }], navigationExtras);
    } else {
      this.router.navigate(['/',
        {
          outlets: {
            'print': ['print', documentName, documentData.join()],
          },
        }]);
    }

  }
  onDataReady() {
    setTimeout(() => {
      window.print();
      this.isPrinting = false;
      this.router.navigate([{ outlets: { print: null } }]);
    });
  }
}

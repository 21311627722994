export interface FooterFactura {
  rowspan?: string;
  colspan?: string;
  type?: string;
  value?: string | number;
  width?: string | number;
}

export interface DatosFactura {
  classTitleTable?: boolean;
  colspan?: number | undefined;
  titleDetalleTable?: string;
  detallesTable?: any[];
  titulo_tabla: string;
  numeroColumnas?: number;
  cabecera?: any[];
  footer?: FooterFactura[][];
  datos: any[][];
  observacionDetalleTable?: any;
  comentarioDetalleTable?: any;
  width?: string | number;
}

export interface configGrafico {
  src: string;
  alt?: string;
  width?: number;
  height?: number;
  posx?: POSX;
  posy?: POSY;
}

export enum POSY {
  'ARRIBA' = 'ARRIBA',
  'ABAJO' = 'ABAJO',
}

export enum POSX {
  'START' = 'start',
  'CENTER' = 'center',
  'END' = 'end',
}
